import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line
import { useLatestArticles } from '../GraphQl/useLatestArticles';
import { useAppState } from '../state/appState';

import { PageMeta } from '../components/pageMeta';
import BlockZone from '~blockZone';
import { HomepageBanner, LatestArticles } from '../components/blockZone/blocks';

const IndexPage = ({ data: { page } }) => {
  const { setGlobalTheme } = useAppState();
  const articles = useLatestArticles();
  const { homepageBanner, meta, pageBlocks } = page || {};

  useEffect(() => {
    setGlobalTheme('white');
  }, [setGlobalTheme]);

  return (
    <>
      <PageMeta {...meta} />
      {homepageBanner && <HomepageBanner {...homepageBanner} />}
      {pageBlocks && <BlockZone {...pageBlocks} />}
      {articles.length > 0 && <LatestArticles />}
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      homepageBanner {
        title
        subtitle
        _rawTextBlock(resolveReferences: { maxDepth: 10 })
        buttonText
        image {
          ...ImageWithPreview
        }
      }
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
